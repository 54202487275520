import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import AMapLoader from '@amap/amap-jsapi-loader';
import bus from "@/plugins/bus";
export default {
  mixins: [mixins],
  name: 'electronicFenceAdd',
  data() {
    return {
      areas: [],
      //区域
      areaProps: {
        value: 'id',
        label: 'name',
        emitPath: false
      },
      edit: false,
      residenceOptions: [],
      form: {
        id: null,
        areaId: '',
        paths: [] // 围栏点坐标；
      },

      formLabelWidth: '150px',
      rules: {
        areaId: [{
          required: true,
          message: '请选择服务区域名称',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请输入地址',
          trigger: 'blur'
        }]
      },
      listLoading: false,
      tableData: [],
      editVisible: false,
      AMap: null,
      map: null,
      centerLngLat: [104.07318, 30.662818],
      // 默认当前坐标为成都市坐标
      initPath: [],
      path: [],
      // 当前绘制的多边形经纬度数组
      polygonItem: [],
      // 地图上绘制的所有多边形对象
      polyEditors: [] // 所有编辑对象数组
    };
  },

  methods: {
    //新增绘制
    onAdd() {
      let scope = this;
      scope.form = {
        id: null,
        name: null,
        address: null,
        path: []
      };
      scope.initPath = [];
      if (scope.initPath && scope.initPath.length > 0) {
        scope.centerLngLat = [scope.initPath[0].lng, scope.initPath[0].lat];
        scope.initPath.forEach(v => {
          scope.form.paths.push({
            lng: v.lng,
            lat: v.lat
          });
        });
      }
      if (scope.initPath) {
        let pt = [];
        scope.initPath.forEach(v => {
          pt.push([v.lng, v.lat]);
        });
        scope.editRectangle(pt);
      }
      scope.editVisible = true;
    },
    closeDT() {
      this.form = {
        id: null,
        name: '',
        address: '',
        paths: [] // 围栏点坐标；
      };

      this.deleRectangle();
      this.$router.go(-1);
    },
    // 中心坐标
    clickZX() {},
    // 保存电子围栏
    saveInfo() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.paths.length === 0) {
            ElMessage.error("未绘制服务区域地图");
          } else {
            const loading = ElLoading.service();
            console.log(this.form);
            const callBack = res => {
              loading.close();
              if (res.code === 200) {
                ElMessage({
                  message: res.message,
                  type: 'success'
                });
                this.$router.back();
                if (this.$route.meta && this.$route.meta.parent) {
                  bus.emit(this.$route.meta.parent, true);
                }
              } else {
                ElMessage.error(res.message);
              }
            };
            if (this.edit) {
              this.$api.business.electronicFence.update(this.form).then(callBack);
            } else {
              this.$api.business.electronicFence.create(this.form).then(callBack);
            }
          }
        }
      });
    },
    // 编辑围栏
    editRectangle(paths) {
      let scope = this;
      let polygon = new window.AMap.Polygon({
        path: paths,
        strokeColor: "#FF33FF",
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        fillColor: '#1791fc',
        zIndex: 50
      });
      scope.map.add(polygon);
      scope.polygonItem.push(polygon);
      // 缩放地图到合适的视野级别
      scope.map.setFitView([polygon]);
      scope.polyEditor = new window.AMap.PolyEditor(this.map, polygon);
      scope.polyEditor.open();
      scope.polyEditors.push(this.polyEditor);
      scope.polyEditor.on('addnode', function (event) {
        console.log(event);
        scope.form.paths = [];
        polygon.getPath().forEach(v => {
          scope.form.paths.push({
            lng: v.lng,
            lat: v.lat
          });
        });
      });
      this.polyEditor.on('adjust', function (event) {
        console.log(event);
        scope.form.paths = [];
        polygon.getPath().forEach(v => {
          scope.form.paths.push({
            lng: v.lng,
            lat: v.lat
          });
        });
      });
      scope.polyEditor.on('removenode', function (event) {
        console.log(event);
        scope.form.paths = [];
        polygon.getPath().forEach(v => {
          scope.form.paths.push({
            lng: v.lng,
            lat: v.lat
          });
        });
      });
      scope.polyEditor.on('end', function (event) {
        console.log(event);
        scope.form.paths = [];
        polygon.getPath().forEach(v => {
          scope.form.paths.push({
            lng: v.lng,
            lat: v.lat
          });
        });
        // event.target 即为编辑后的多边形对象
      });
    },

    // 绘制多边形
    drawRectangle() {
      const vm = this;
      this.deleRectangle(); // 清空多边形
      vm.mouseTool = new window.AMap.MouseTool(this.map);
      const polygon = vm.mouseTool.polygon({
        strokeColor: 'red',
        strokeOpacity: 0.5,
        strokeWeight: 6,
        fillColor: 'blue',
        fillOpacity: 0.5,
        // strokeStyle还支持 solid
        strokeStyle: 'solid'
        // strokeDasharray: [30,10],
      });

      console.log(polygon);
      vm.mouseTool.on('draw', function (event) {
        // event.obj 为绘制出来的覆盖物对象
        var polygonItem = event.obj;
        var paths = polygonItem.getPath(); //取得绘制的多边形的每一个点坐标
        var path = []; // 编辑的路径
        vm.form.paths = [];
        paths.forEach(v => {
          path.push([v.lng, v.lat]);
          vm.form.paths.push({
            lng: v.lng,
            lat: v.lat
          });
        });
        vm.path = path;
        vm.editRectangle(vm.path);
        vm.polygonItem.push(event.obj);
        vm.map.remove(event.obj); // 删除多边形
      });
    },

    // 批量删除多边形
    deleRectangle() {
      let scope = this;
      // 取消编辑状态
      console.log('批量删除多边形 - deleRectangle', scope.polyEditors);
      if (scope.polyEditors && scope.polyEditors.length > 0) {
        scope.polyEditors.forEach(v => {
          v.close();
        });
      }
      scope.form.paths = []; // 清空 form 中的坐标信息
      if (scope.map) {
        scope.map.clearMap(); // 删除地图所有覆盖物
      }
    },

    getAreas() {
      this.areas = [];
      this.$api.uac.area.list().then(res => {
        this.areas = res.data;
      });
    }
  },
  beforeMount() {
    console.log('---------------');
    this.getAreas();
    this.form = {
      id: null,
      name: '',
      address: '',
      paths: [] // 围栏点坐标；
    };

    AMapLoader.load({
      "key": this.$amapKey,
      // 申请好的Web端开发者Key，首次调用 load 时必填
      "version": "2.0",
      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      "plugins": ['AMap.Scale', 'AMap.PolyEditor', 'AMap.MouseTool', 'AMap.Polygon'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then(AMap => {
      this.map = new AMap.Map("amapContainer", {
        center: this.centerLngLat,
        zoom: 14,
        // mapStyle: "amap://styles/darkblue",
        pitch: 0,
        // 指南针南北方向
        rotation: 0,
        // 指南针东西方向
        // viewMode: '3D',//开启3D视图,默认为关闭
        buildingAnimation: false //楼块出现是否带动画
      });

      if (this.$route.query.id) {
        this.edit = true;
        const loading = ElLoading.service();
        this.$api.business.electronicFence.detail({
          id: this.$route.query.id
        }).then(res => {
          if (res.code === 200) {
            console.log(res.data);
            this.form = res.data;
            if (res.data.paths) {
              let pt = [];
              res.data.paths.forEach(v => {
                pt.push([v.lng, v.lat]);
              });
              this.editRectangle(pt);
            }
          } else if (res.code === 121601) {
            this.$router.replace({
              name: '404'
            });
          } else {
            ElMessage.error(res.message);
            this.$router.back();
          }
        }).finally(() => {
          loading.close();
        });
      }
    }).catch(e => {
      console.log('高德初始化失败', e);
    });
  }
};